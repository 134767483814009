import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { PortableText } from '@portabletext/react';
import urlBuilder from '@sanity/image-url';
import { getImageDimensions } from '@sanity/asset-utils';
import SEO from '../components/SEO';

const SupportStyled = styled.div`
  max-width: calc(var(--wrapper) * 0.5);
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  h3 {
    border-top: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
    padding: 4rem;
    margin: 2rem 0;
    text-align: center;
  }
  input.needsclick {
    border: 1px solid rgb(96, 106, 114);
    border-bottom: 1px solid rgb(96, 106, 114) !important;
  }
  h6 {
    border-top: 1px solid var(--border);
    text-transform: normal;
    padding-top: 2rem;
  }
  .bc-size-chart,
  .table-wrapper {
    overflow-x: auto;
    white-space: nowrap;
  }
  .table-wrapper > table,
  .table-wrapper > table th,
  .table-wrapper > table td {
    padding: 5px;
    font-size: 13px;
    border: 1px solid #eee !important;
    border-collapse: collapse;
    table-layout: auto;
  }
  .table-wrapper > table {
    margin-bottom: 5px;
  }

  .table-wrapper > table th {
    background: var(--greyLight);
    text-align: right;
    position: sticky;
    left: 0;
  }

  .table-wrapper > table td {
    text-align: center;
  }
`;

// Barebones lazy-loaded image component
const SampleImageComponent = ({ value }) => {
  const { width, height } = getImageDimensions(value);
  return (
    <img
      src={urlBuilder().image(value).width(800).fit('max').auto('format').url()}
      alt={value.alt || ' '}
      loading="lazy"
      style={{
        // Avoid jumping around with aspect-ratio CSS property
        aspectRatio: width / height,
      }}
    />
  );
};

const serializers = {
  block: {
    h1: ({ children }) => <h1 className="heading-h1">{children}</h1>,
    h2: ({ children }) => <h2 className="heading-h2">{children}</h2>,
    h3: ({ children }) => <h3 className="heading-h3">{children}</h3>,
    h4: ({ children }) => <h4 className="heading-h4">{children}</h4>,
    h5: ({ children }) => <h5 className="heading-h5">{children}</h5>,
    h6: ({ children }) => <h6 className="heading-h6">{children}</h6>,
    blockquote: ({ children }) => <blockquote>{children}</blockquote>,
    quote: ({ children }) => <blockquote>{children}</blockquote>,
    list: {
      bullet: ({ children }) => <ul>{children}</ul>,
      number: ({ children }) => <ol>{children}</ol>,
    },
    listItem: {
      bullet: ({ children }) => <li>{children}</li>,
    },
    marks: {
      strong: ({ children }) => <strong>{children}</strong>,
      em: ({ children }) => <em>{children}</em>,
      code: ({ children }) => <code>{children}</code>,
    },
    types: {
      image: SampleImageComponent,
    },
  },
};

export default function SingleSupportPage({ data, pageContext }) {
  const { support } = data;
  return (
    <>
      <SEO title={pageContext.title} />
      <SupportStyled>
        <div className="wrapper">
          <h3>{pageContext.title}</h3>
          <PortableText
            value={pageContext.content}
            projectId="9xe1g1l8"
            dataset="production"
            components={serializers}
          />
        </div>
        {pageContext.title === 'Dealer enquiries' && (
          <div className="klaviyo-form-SAjsBq" />
        )}
        {pageContext.title === 'Shoe Size Finder' && (
          <div className="bc-size-chart">
            <p>
              <strong>
                Vaypor SL, Vaypor BOA (2023), Vaypor Lace, Riot 24 and Riot G
              </strong>
            </p>
            <div className="table-wrapper">
              <table>
                <tbody>
                  <tr>
                    <th>Bont Cycling EU</th>
                    <td>4</td>
                    <td>4½</td>
                    <td>5</td>
                    <td>6</td>
                    <td>6½</td>
                    <td>7</td>
                    <td>7½</td>
                    <td>8</td>
                    <td>8½</td>
                    <td>9</td>
                    <td>9½</td>
                    <td>10</td>
                    <td>10½</td>
                    <td>11</td>
                    <td>11½</td>
                    <td>12</td>
                    <td>12½</td>
                    <td>13</td>
                    <td>14</td>
                  </tr>
                  <tr>
                    <th>Bont Cycling USA</th>
                    <td>36</td>
                    <td>37</td>
                    <td>38</td>
                    <td>39</td>
                    <td>40</td>
                    <td>40.5</td>
                    <td>41</td>
                    <td>42</td>
                    <td>42.5</td>
                    <td>43</td>
                    <td>44</td>
                    <td>44.5</td>
                    <td>45</td>
                    <td>46</td>
                    <td>46.5</td>
                    <td>47</td>
                    <td>48</td>
                    <td>49</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <th>Internal Shoe Length</th>
                    <td>225</td>
                    <td>230</td>
                    <td>235</td>
                    <td>240</td>
                    <td>245</td>
                    <td>250</td>
                    <td>255</td>
                    <td>260</td>
                    <td>265</td>
                    <td>270</td>
                    <td>275</td>
                    <td>280</td>
                    <td>285</td>
                    <td>290</td>
                    <td>295</td>
                    <td>300</td>
                    <td>305</td>
                    <td>310</td>
                    <td>315</td>
                  </tr>
                  <tr>
                    <th>Foot Length Range</th>
                    <td>216 - 220</td>
                    <td>221 - 225</td>
                    <td>226 - 230</td>
                    <td>231 - 235</td>
                    <td>236 - 240</td>
                    <td>241 - 245</td>
                    <td>246 - 250</td>
                    <td>251 - 255</td>
                    <td>256 - 260</td>
                    <td>261 - 265</td>
                    <td>266 - 270</td>
                    <td>271 - 275</td>
                    <td>276 - 280</td>
                    <td>281 - 285</td>
                    <td>286 - 290</td>
                    <td>291 - 295</td>
                    <td>296 - 300</td>
                    <td>301 - 305</td>
                    <td>306 - 310</td>
                  </tr>
                  <tr>
                    <th>Standard Range</th>
                    <td>84 - 95</td>
                    <td>85 - 96</td>
                    <td>87 - 97</td>
                    <td>88 - 99</td>
                    <td>89 - 100</td>
                    <td>91 - 102</td>
                    <td>92 - 104</td>
                    <td>93 - 105</td>
                    <td>94 - 107</td>
                    <td>96 - 108</td>
                    <td>97 - 110</td>
                    <td>98 - 111</td>
                    <td>100 - 112</td>
                    <td>101 - 114</td>
                    <td>102 - 115</td>
                    <td>104 - 116</td>
                    <td>105 - 118</td>
                    <td>106 - 119</td>
                    <td>107 - 121</td>
                  </tr>
                  <tr>
                    <th>Wide Range</th>
                    <td>96 - 101</td>
                    <td>97 - 102</td>
                    <td>98 - 104</td>
                    <td>100 - 105</td>
                    <td>101 - 107</td>
                    <td>103 - 109</td>
                    <td>105 - 110</td>
                    <td>106 - 112</td>
                    <td>108 - 114</td>
                    <td>109 - 115</td>
                    <td>111 - 117</td>
                    <td>112 - 118</td>
                    <td>113 - 120</td>
                    <td>115 - 121</td>
                    <td>116 - 123</td>
                    <td>117 - 124</td>
                    <td>119 - 126</td>
                    <td>120 - 128</td>
                    <td>122 - 129</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>
              <strong>
                Vaypor S, Helix, Zero+, Vaypor+ Kangaroo, Vaypor G and Crono MK2
              </strong>
            </p>
            <div className="table-wrapper">
              <table>
                <tbody>
                  <tr>
                    <th>Bont Cycling EU</th>
                    <td>36</td>
                    <td>37</td>
                    <td>38</td>
                    <td>39</td>
                    <td>40</td>
                    <td>40.5</td>
                    <td>41</td>
                    <td>42</td>
                    <td>42.5</td>
                    <td>43</td>
                    <td>44</td>
                    <td>44.5</td>
                    <td>45</td>
                    <td>46</td>
                    <td>46.5</td>
                    <td>47</td>
                    <td>48</td>
                    <td>49</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <th>Bont Cycling USA</th>
                    <td>4</td>
                    <td>4½</td>
                    <td>5</td>
                    <td>6</td>
                    <td>6½</td>
                    <td>7</td>
                    <td>7½</td>
                    <td>8</td>
                    <td>8½</td>
                    <td>9</td>
                    <td>9½</td>
                    <td>10</td>
                    <td>10½</td>
                    <td>11</td>
                    <td>11½</td>
                    <td>12</td>
                    <td>12½</td>
                    <td>13</td>
                    <td>14</td>
                  </tr>
                  <tr>
                    <th>Internal Shoe Length</th>
                    <td>225</td>
                    <td>230</td>
                    <td>235</td>
                    <td>240</td>
                    <td>245</td>
                    <td>250</td>
                    <td>255</td>
                    <td>260</td>
                    <td>265</td>
                    <td>270</td>
                    <td>275</td>
                    <td>280</td>
                    <td>285</td>
                    <td>290</td>
                    <td>295</td>
                    <td>300</td>
                    <td>305</td>
                    <td>310</td>
                    <td>315</td>
                  </tr>
                  <tr className="flr-row">
                    <th>Foot Length Range</th>
                    <td>216-220</td>
                    <td>221-225</td>
                    <td>226-230</td>
                    <td>231-235</td>
                    <td>236-240</td>
                    <td>241-245</td>
                    <td>246-250</td>
                    <td>251-255</td>
                    <td>256-260</td>
                    <td>261-265</td>
                    <td>266-270</td>
                    <td>271-275</td>
                    <td>276-280</td>
                    <td>281-285</td>
                    <td>286-290</td>
                    <td>291-295</td>
                    <td>296-300</td>
                    <td>301-305</td>
                    <td>306-310</td>
                  </tr>
                  <tr>
                    <th>Narrow Range</th>
                    <td>81-83</td>
                    <td>82-84</td>
                    <td>83-86</td>
                    <td>84-87</td>
                    <td>86-88</td>
                    <td>87-90</td>
                    <td>88-91</td>
                    <td>90-92</td>
                    <td>91-93</td>
                    <td>92-95</td>
                    <td>93-96</td>
                    <td>95-97</td>
                    <td>96-99</td>
                    <td>97-100</td>
                    <td>99-101</td>
                    <td>100-103</td>
                    <td>101-104</td>
                    <td>103-105</td>
                    <td>104-106</td>
                  </tr>
                  <tr>
                    <th>Standard Range</th>
                    <td>84-93</td>
                    <td>85-94</td>
                    <td>87-95</td>
                    <td>88-97</td>
                    <td>89-98</td>
                    <td>91-100</td>
                    <td>92-102</td>
                    <td>93-103</td>
                    <td>94-105</td>
                    <td>96-106</td>
                    <td>97-107</td>
                    <td>98-108</td>
                    <td>100-110</td>
                    <td>101-111</td>
                    <td>102-113</td>
                    <td>104-114</td>
                    <td>105-116</td>
                    <td>106-117</td>
                    <td>107-119</td>
                  </tr>
                  <tr>
                    <th>Wide Range</th>
                    <td>94-97</td>
                    <td>95-98</td>
                    <td>96-100</td>
                    <td>98-101</td>
                    <td>99-103</td>
                    <td>101-104</td>
                    <td>103-106</td>
                    <td>104-107</td>
                    <td>106-109</td>
                    <td>107-110</td>
                    <td>108-112</td>
                    <td>109-113</td>
                    <td>111-115</td>
                    <td>112-116</td>
                    <td>114-118</td>
                    <td>115-119</td>
                    <td>117-121</td>
                    <td>118-122</td>
                    <td>120-124</td>
                  </tr>
                  <tr>
                    <th>Double Range</th>
                    <td>98-102</td>
                    <td>99-103</td>
                    <td>101-105</td>
                    <td>102-106</td>
                    <td>104-108</td>
                    <td>105-110</td>
                    <td>107-111</td>
                    <td>108-113</td>
                    <td>110-115</td>
                    <td>111-116</td>
                    <td>113-118</td>
                    <td>114-119</td>
                    <td>116-121</td>
                    <td>117-122</td>
                    <td>119-124</td>
                    <td>120-125</td>
                    <td>122-127</td>
                    <td>123-129</td>
                    <td>125-130</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p>
              <strong>Vaypor T</strong>
            </p>
            <div className="table-wrapper">
              <table>
                <tbody>
                  <tr>
                    <th>Bont Cycling EU</th>
                    <td>36</td>
                    <td>37</td>
                    <td>38</td>
                    <td>39</td>
                    <td>40</td>
                    <td>40.5</td>
                    <td>41</td>
                    <td>42</td>
                    <td>42.5</td>
                    <td>43</td>
                    <td>44</td>
                    <td>44.5</td>
                    <td>45</td>
                    <td>46</td>
                    <td>46.5</td>
                    <td>47</td>
                    <td>48</td>
                    <td>49</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <th>Bont Cycling USA</th>
                    <td>3½</td>
                    <td>4½</td>
                    <td>5</td>
                    <td>6</td>
                    <td>6½</td>
                    <td>7</td>
                    <td>7½</td>
                    <td>8</td>
                    <td>8½</td>
                    <td>9</td>
                    <td>10</td>
                    <td>10½</td>
                    <td>10¾</td>
                    <td>11</td>
                    <td>11½</td>
                    <td>12</td>
                    <td>12½</td>
                    <td>13</td>
                    <td>14</td>
                  </tr>
                  <tr>
                    <th>Internal Shoe Length</th>
                    <td>223</td>
                    <td>232</td>
                    <td>238</td>
                    <td>245</td>
                    <td>250</td>
                    <td>254</td>
                    <td>259</td>
                    <td>263</td>
                    <td>268</td>
                    <td>272</td>
                    <td>277</td>
                    <td>281</td>
                    <td>285</td>
                    <td>290</td>
                    <td>294</td>
                    <td>299</td>
                    <td>305</td>
                    <td>312</td>
                    <td>317</td>
                  </tr>
                  <tr>
                    <th>Foot Length Range</th>
                    <td>210-218</td>
                    <td>219-227</td>
                    <td>228-233</td>
                    <td>234-240</td>
                    <td>241-245</td>
                    <td>246-249</td>
                    <td>250-254</td>
                    <td>255-258</td>
                    <td>259-263</td>
                    <td>264-267</td>
                    <td>268-272</td>
                    <td>273-276</td>
                    <td>277-280</td>
                    <td>281-285</td>
                    <td>286-289</td>
                    <td>290-294</td>
                    <td>295-300</td>
                    <td>301-307</td>
                    <td>308-312</td>
                  </tr>
                  <tr>
                    <th>Narrow range</th>
                    <td>76-80</td>
                    <td>78-82</td>
                    <td>80-84</td>
                    <td>82-86</td>
                    <td>83-87</td>
                    <td>85-89</td>
                    <td>86-90</td>
                    <td>87-91</td>
                    <td>89-93</td>
                    <td>90-94</td>
                    <td>91-95</td>
                    <td>93-97</td>
                    <td>94-98</td>
                    <td>95-99</td>
                    <td>96-100</td>
                    <td>97-101</td>
                    <td>99-103</td>
                    <td>101-105</td>
                    <td>102-106</td>
                  </tr>
                  <tr>
                    <th>Standard range</th>
                    <td>81-90</td>
                    <td>83-92</td>
                    <td>85-94</td>
                    <td>87-97</td>
                    <td>88-98</td>
                    <td>90-100</td>
                    <td>91-101</td>
                    <td>92-102</td>
                    <td>94-104</td>
                    <td>95-105</td>
                    <td>96-107</td>
                    <td>98-109</td>
                    <td>99-110</td>
                    <td>100-111</td>
                    <td>101-112</td>
                    <td>102-113</td>
                    <td>104-115</td>
                    <td>106-117</td>
                    <td>107-118</td>
                    <td> </td>
                  </tr>
                  <tr>
                    <th>Wide range</th>
                    <td>91-95</td>
                    <td>93-97</td>
                    <td>95-99</td>
                    <td>98-102</td>
                    <td>99-103</td>
                    <td>101-105</td>
                    <td>102-106</td>
                    <td>103-107</td>
                    <td>105-109</td>
                    <td>106-110</td>
                    <td>108-112</td>
                    <td>110-114</td>
                    <td>111-115</td>
                    <td>112-116</td>
                    <td>113-117</td>
                    <td>114-118</td>
                    <td>116-120</td>
                    <td>118-122</td>
                    <td>119-123</td>
                  </tr>
                  <tr>
                    <th>Double range</th>
                    <td>96-100</td>
                    <td>98-102</td>
                    <td>100-104</td>
                    <td>103-107</td>
                    <td>104-108</td>
                    <td>106-110</td>
                    <td>107-111</td>
                    <td>108-112</td>
                    <td>110-114</td>
                    <td>111-115</td>
                    <td>113-117</td>
                    <td>115-119</td>
                    <td>116-120</td>
                    <td>117-121</td>
                    <td>118-122</td>
                    <td>119-123</td>
                    <td>121-125</td>
                    <td>123-127</td>
                    <td>124-128</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p>
              <strong>Riot, Riot+ BOA and Riot MTB+</strong>
            </p>
            <div className="table-wrapper">
              <table>
                <tbody>
                  <tr>
                    <th>Bont Cycling EU</th>
                    <td>36</td>
                    <td>37</td>
                    <td>38</td>
                    <td>39</td>
                    <td>40</td>
                    <td>40.5</td>
                    <td>41</td>
                    <td>42</td>
                    <td>42.5</td>
                    <td>43</td>
                    <td>44</td>
                    <td>44.5</td>
                    <td>45</td>
                    <td>46</td>
                    <td>46.5</td>
                    <td>47</td>
                    <td>48</td>
                    <td>49</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <th>Bont Cycling USA</th>
                    <td>4</td>
                    <td>4½</td>
                    <td>5</td>
                    <td>6</td>
                    <td>6½</td>
                    <td>7</td>
                    <td>7½</td>
                    <td>8</td>
                    <td>8½</td>
                    <td>9</td>
                    <td>9½</td>
                    <td>10</td>
                    <td>10½</td>
                    <td>11</td>
                    <td>11½</td>
                    <td>12</td>
                    <td>12½</td>
                    <td>13</td>
                    <td>14</td>
                  </tr>
                  <tr>
                    <th>Internal Shoe Length</th>
                    <td>225</td>
                    <td>230</td>
                    <td>235</td>
                    <td>240</td>
                    <td>245</td>
                    <td>250</td>
                    <td>255</td>
                    <td>260</td>
                    <td>265</td>
                    <td>270</td>
                    <td>275</td>
                    <td>280</td>
                    <td>285</td>
                    <td>290</td>
                    <td>295</td>
                    <td>300</td>
                    <td>305</td>
                    <td>310</td>
                    <td>315</td>
                  </tr>
                  <tr>
                    <th>Foot Length Range</th>
                    <td>216-220</td>
                    <td>221-225</td>
                    <td>226-230</td>
                    <td>231-235</td>
                    <td>236-240</td>
                    <td>241-245</td>
                    <td>246-250</td>
                    <td>251-255</td>
                    <td>256-260</td>
                    <td>261-265</td>
                    <td>266-270</td>
                    <td>271-275</td>
                    <td>276-280</td>
                    <td>281-285</td>
                    <td>286-290</td>
                    <td>291-295</td>
                    <td>296-300</td>
                    <td>301-305</td>
                    <td>306-310</td>
                  </tr>
                  <tr>
                    <th>Standard Range</th>
                    <td>84-95</td>
                    <td>85-96</td>
                    <td>87-98</td>
                    <td>88-99</td>
                    <td>89-101</td>
                    <td>91-102</td>
                    <td>92-104</td>
                    <td>93-105</td>
                    <td>94-107</td>
                    <td>96-108</td>
                    <td>97-110</td>
                    <td>98-111</td>
                    <td>100-113</td>
                    <td>101-114</td>
                    <td>102-115</td>
                    <td>104-117</td>
                    <td>105-118</td>
                    <td>106-120</td>
                    <td>107-121</td>
                  </tr>
                  <tr>
                    <th>Wide Range</th>
                    <td>96-97</td>
                    <td>97-98</td>
                    <td>99-100</td>
                    <td>100-101</td>
                    <td>102-103</td>
                    <td>103-104</td>
                    <td>105-106</td>
                    <td>106-107</td>
                    <td>108-109</td>
                    <td>109-110</td>
                    <td>111-112</td>
                    <td>112-113</td>
                    <td>114-115</td>
                    <td>115-116</td>
                    <td>116-118</td>
                    <td>118-119</td>
                    <td>119-121</td>
                    <td>121-122</td>
                    <td>122-124</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p>
              <strong>Motion</strong>
            </p>
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Bont Cycling EU</th>
                    <td>36</td>
                    <td>37</td>
                    <td>38</td>
                    <td>39</td>
                    <td>40</td>
                    <td>40.5</td>
                    <td>41</td>
                    <td>42</td>
                    <td>42.5</td>
                    <td>43</td>
                    <td>44</td>
                    <td>44.5</td>
                    <td>45</td>
                    <td>46</td>
                    <td>46.5</td>
                    <td>47</td>
                    <td>48</td>
                    <td>49</td>
                    <td>50</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Bont Cycling USA</th>
                    <td>4</td>
                    <td>4½</td>
                    <td>5</td>
                    <td>6</td>
                    <td>6½</td>
                    <td>7</td>
                    <td>7½</td>
                    <td>8</td>
                    <td>8½</td>
                    <td>9</td>
                    <td>9½</td>
                    <td>10</td>
                    <td>10½</td>
                    <td>11</td>
                    <td>11½</td>
                    <td>12</td>
                    <td>12½</td>
                    <td>13</td>
                    <td>14</td>
                  </tr>
                  <tr>
                    <th>Internal Shoe Length</th>
                    <td>225</td>
                    <td>230</td>
                    <td>235</td>
                    <td>240</td>
                    <td>245</td>
                    <td>250</td>
                    <td>255</td>
                    <td>260</td>
                    <td>265</td>
                    <td>270</td>
                    <td>275</td>
                    <td>280</td>
                    <td>285</td>
                    <td>290</td>
                    <td>295</td>
                    <td>300</td>
                    <td>305</td>
                    <td>310</td>
                    <td>315</td>
                  </tr>
                  <tr>
                    <th>Foot Length Range</th>
                    <td>216-220</td>
                    <td>221-225</td>
                    <td>226-230</td>
                    <td>231-235</td>
                    <td>236-240</td>
                    <td>241-245</td>
                    <td>246-250</td>
                    <td>251-255</td>
                    <td>256-260</td>
                    <td>261-265</td>
                    <td>266-270</td>
                    <td>271-275</td>
                    <td>276-280</td>
                    <td>281-285</td>
                    <td>286-290</td>
                    <td>291-295</td>
                    <td>296-300</td>
                    <td>301-305</td>
                    <td>306-310</td>
                  </tr>
                  <tr>
                    <th>Width Range</th>
                    <td>84-93</td>
                    <td>85-94</td>
                    <td>87-95</td>
                    <td>88-97</td>
                    <td>89-98</td>
                    <td>91-100</td>
                    <td>92-102</td>
                    <td>93-103</td>
                    <td>94-105</td>
                    <td>96-106</td>
                    <td>97-107</td>
                    <td>98-108</td>
                    <td>100-110</td>
                    <td>101-111</td>
                    <td>102-113</td>
                    <td>104-114</td>
                    <td>105-116</td>
                    <td>106-117</td>
                    <td>107-119</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </SupportStyled>
    </>
  );
}

export const query = graphql`
  query ($slug: String!) {
    support: sanitySupport(
      supportPages: { elemMatch: { slug: { current: { eq: $slug } } } }
    ) {
      supportPages {
        title
        slug {
          current
        }
        _rawContent
      }
    }
  }
`;
